import React from "react";

import Layout from "../components/layout";
import RightColours from "../utilities/RightColours";
import LeftColours from "../utilities/LeftColours";
import StandardHeader from "../assets/elements/StandardHeader";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import ThreeColumnUseCaseSection from "../assets/elements/ThreeColumnUseCaseSection";

import SvgPersonDataLabel from "../assets/icons/person-data-labeller.svg";
import SvgDataCompliance from "../assets/icons/product-data-compliance.svg";
import SvgPersonDataEngineer from "../assets/icons/person-data-engineer.svg";
import SvgProductData from "../assets/icons/product-data.svg";
import SEO from "../components/seo";
import Cta from "../partials/Cta";

const useCasesImageHeight = 198;
const useCasesImageWidth = 352;

const useCases = [
  {
    name: "Employee Training",
    description:
      "At Encord, security is a priority for all employees. " +
      "Everyone working at Encord has to take part in an annual HIPAA training program and annual SOC 2 security training.",
    image: (
      <SvgPersonDataLabel
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt="Employee Training"
      />
    ),
  },
  {
    name: "Secure Software Development",
    description:
      "Encord utilises a variety of manual and automatic data security and vulnerability checks " +
      "throughout the software development lifecycle.",
    image: (
      <SvgPersonDataEngineer
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt="Secure Software Development"
      />
    ),
  },
  {
    name: "Data encryption",
    description:
      "Any data passing through the Encord platform is encrypted both in-transit using TLS and at rest.",
    image: (
      <SvgProductData
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt="Data encryption"
      />
    ),
  },
];

const headerImages = [
  <a href="https://drata.com/hipaa/" target="_blank" rel="noopener noreferrer">
    <img
      src="https://cdn.drata.com/badge/hipaa-light.png"
      alt="hipaa badge"
      loading="lazy"
      className="min-w-32 h-auto"
      width={0}
      height={0}
    />
  </a>,
  <a href=" https://drata.com/product/" target="_blank">
    <img
      src="https://cdn.drata.com/badge/soc2-dark.png"
      alt="Soc2 badge"
      loading="lazy"
      className="min-w-32 h-auto"
      width={0}
      height={0}
    />
  </a>,
  <a href="https://us.aicpa.org/interestareas/frc/">
    <div className="w-32">
      <img
        src={
          "https://images.prismic.io/encord/d5a5f02e-d8df-49c2-9413-5633a8e75e7d_soc2-certificate.png?auto=compress,format"
        }
        alt="Soc2 certificate"
        loading="lazy"
        className="w-auto h-auto"
        width={0}
        height={0}
      />
    </div>
  </a>,
  <a href="https://gdpr.eu/">
    <div className="w-32">
      <img
        src={
          "https://images.prismic.io/encord/65fdfab8-2400-4953-a16f-634597c30373_gdpr.png?auto=compress,format"
        }
        alt="GD PR logo"
        loading="lazy"
        className="w-auto h-auto"
        width={0}
        height={0}
      />
    </div>
  </a>,
];

const SecurityAtEncord = ({ location }) => {
  return (
    <Layout location={location}>
      {/*  Page illustration */}
      <LeftColours />
      <RightColours />

      <StandardHeader
        label={"security first"}
        title={"Data Security at Encord"}
        description={
          "At Encord, we take our security commitments very seriously. When working with us and using our services, you can ensure your and your customer's data is safe and secure."
        }
        displayImages={headerImages}
        hideDemoRequest
      />

      <TwoFeatureOverview
        includeHeader={true}
        includeGrayTransition={true}
        includeTopPadding={true}
        imageOne={
          <img
            src={
              "https://images.prismic.io/encord/d5a5f02e-d8df-49c2-9413-5633a8e75e7d_soc2-certificate.png?auto=compress,format"
            }
            loading="lazy"
            alt="Soc2 certificate"
            className="h-72 w-72"
            width={0}
            height={0}
          />
        }
        imageTwo={
          <a
            href="https://drata.com/hipaa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://cdn.drata.com/badge/hipaa-light.png"
              alt="hipaa badge"
              className="w-auto h-72"
              loading="lazy"
              width={0}
              height={0}
            />
          </a>
        }
        imageThree={
          <SvgDataCompliance className="flex items-center justify-end h-96" />
        }
        imageWidthOne={550}
        imageWidthTwo={500}
        imageWidthThree={500}
        headerOne={"AICPA SOC 2 Certified"}
        headerTwo={"HIPAA Certified"}
        headerThree={"Continuous Security Control Monitoring"}
        descriptionOne={
          <>
            Encord has successfully completed its Systems and Organizational
            Control (SOC)-2 Examination. In doing so Encord maintains its
            adherence to one of the most stringent, industry-accepted compliance
            frameworks for service organizations and provides additional
            assurance to its clients, through an independent auditor (
            <a
              className="text-blue-600 hover:underline"
              href="https://www.darata.com/"
            >
              Dansa D'Arata Soucia LLP
            </a>
            ), that its business process, information technology and risk
            management controls are properly designed.
          </>
        }
        descriptionTwo={
          "Encord utilizes enterprise-grade best practices to protect our customers' " +
          "sensitive health information and uses Drata to verify its security, privacy," +
          " and HIPAA compliance controls. Drata's HIPAA product provides an automated " +
          "approach to ensuring that organizations can demonstrate compliance."
        }
        descriptionThree={
          <>
            Encord uses{" "}
            <a
              className="text-blue-600 hover:underline"
              href="https://drata.com/"
            >
              Drata's
            </a>{" "}
            automation platform to continuously monitor security controls across
            the organization. Automated alerts and evidence collection allow
            Encord to confidently prove its commitment to protecting your
            sensitive health information while fostering a security-first
            mindset and culture of compliance across the organization.
          </>
        }
      />

      <ThreeColumnUseCaseSection useCases={useCases} />

      <section className="relative border-t border-gray-200 bg-gradient-to-b from-gray-100 to-white">
        <div className="relative max-w-6xl px-4 mx-auto my-6 sm:px-6">
          <div className="py-8">
            <div className="text-center h4">
              Vulnerability disclosure program
            </div>
            <p className="mt-2 text-xl text-center text-gray-600">
              If you believe you've discovered a bug in Encord's security,
              please get in touch at{" "}
              <a
                className="text-blue-600 hover:underline"
                href="mailto:security@encord.com"
              >
                security@encord.com
              </a>
              . Our security team promptly investigates all reported issues.
            </p>
          </div>
        </div>
      </section>
      <Cta
        location={location}
        pageName={"Security at Encord Page"}
        ctaText="Learn More About Annotation and Labeling Security with Encord"
      />
    </Layout>
  );
};

export default SecurityAtEncord;

export const Head = () => (
  <SEO
    title="AI and ML Data Security - Computer Vision Platform | Encord"
    description="At Encord, we take our security commitments very seriously. When working with us and using our active learning platform for computer vision, you can ensure your and your customers' data are safe and secure."
  />
);
